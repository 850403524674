<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";

export default {
    page: { 
        title: "Finance Dashboard",
        meta: [
            {
                name: "description",
                content: appConfig.description,
            },
        ],
    },
    components: {Layout, PageHeader, Multiselect, DatePicker},
    data() {
        return {
            isTableBusy: false,
            tableData: [],
            
            title: "Finance Dashboard",
            items: [
                {
                    text: "View",
                },
                {
                    text: "Finance Dashboard",
                    active: true,
                },
            ],
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10, 25, 50, 100,500,1000],
            filter: null,
            filterOn: [],
            sortBy: "invoiceDate",
            visibleDataMessage: "",
            nonInvoicedOrders:0,
          nonInvOrders:null,
          generatingInvoice:false,
            sortDesc: true,
            country:"",
            countryArr:[],
            state: "",
            stateArr: [],
            city:"",
            cityArr:[],
            companyID:0,
            companyArr:[],
            brandData : "",
            brandArr :[],
            branchID:"",
            branches:[],
            restBranchID:this.$storageData.profile.restBranchID,
            daterange: [],
            cardData:[],
            paymentType : "",
            paymentTypeArr : ["Digital","Wallet Sales","Subsidy Sales","Pre-Buy Voucher"],
            orderType:"",
            orderTypeArr :["Cafeteria / Food Court","Catering for Events","Restuarant Near By","Curated Dining Experience"],
            tableView: "",
            tableViewArr : [
                { name: "Date Wise View", id: 1 },
                { name: "Location Wise View", id: 2 },
                { name: "City Wise View", id: 3 },
                { name: "State Wise View", id: 4 },
            ],
            // visibleFields: true,
            getFields: [],
            fields: [
                {
                    key: "invoiceDate",
                    label: 'Date',
                    sortable: true,
                },
                {
                    key: "subTotal",
                    label: 'Sub Total',
                    sortable: true,
                    tdClass: "align-right",
                },
                {
                    key: "CGST",
                    sortable: true,
                    tdClass: "align-right",
                },
                {
                    key: "SGST",
                    sortable: true,
                    tdClass: "align-right",
                },
                {
                    key: "total",
                    sortable: true,
                    tdClass: "align-right",
                },
                {
                    key: "subsidy",
                    sortable: true,
                    tdClass: "align-right",
                },   
                {
                    key: "cashVoucher",
                    label: 'Cash Voucher',
                    sortable: true,
                    tdClass: "align-right",
                },  
                {
                    key: "wallet",
                    label: 'Wallet',
                    sortable: true,
                    tdClass: "align-right",
                },  
                {
                    key: "netPaid",
                    label: 'Net Paid',
                    sortable: true,
                    tdClass: "align-right",
                },  
            ],
        };
    },
    
    computed: {
        /**
        * Total no. of records
        */
        rows() {
           return this.tableData.length;
        },
    },

    mounted() {
        // Set defualt value selected from options 
        this.tableView = this.tableViewArr.find(tableViewArr => tableViewArr.id === 1);

        // Set the initial number of items
        this.totalRows = this.items.length;

        // if(this.$storageData.profile.empTypeID == 1 ){
            
        // }
        // else if(this.$storageData.profile.empTypeID == 2 || this.$storageData.profile.empTypeID == 11){
        //     this.getRestaurantCountry();      
        // }
        this.getAllCountry();
        this.getAllStates();
        // this.getAllCities();
        // this.getAllCompanies();
        // this.getRestaurantBrandsByCompanyID();
        // this.getRestaurantBrands();
        // this.readRestaurantBranchData();
        // this.financeDashboard();
    },

    methods: {
        //excel code
        downloadSample() {
            this.axios.post(
                "exportExcel", {
                    exportData: this.tableData,
                    page: "Sales Mix Report",
                },
                {
                    responseType: "blob",
                }
            )
            .then((response) => {
                const url = URL.createObjectURL(
                    new Blob([response.data], {
                        type: "application/vnd.ms-excel",
                    })
                );
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", "Finance Dashboard Data.xlsx");
                document.body.appendChild(link);
                link.click();
            });
        },
        //excel code end

        /**
        * Search the table data with search input
        */
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },

        // Get All Country List 
        getAllCountry(){
            this.countryArr = [];
            this.country = "";
            this.axios.post(this.$loggedRole+"/getAllCountry")
            .then((response) => {
                this.countryArr = response.data.data;  
            });
        },

        getRestaurantCountry(){
            this.axios.post(this.$loggedRole+"/getRestaurantCountry", {
                    restID:this.$storageData.profile.restID,
                }
            )
            .then((response) => {
                this.countryArr = response.data.data;
                this.country = (this.countryArr) ? this.countryArr[0] : "";
                this.getAllCompanies();       
            });
        },

        // Get All States List
        getAllStates() {
            this.state = "";
            this.stateArr = [];
            this.axios.get(this.$loggedRole+"/getAllState", {
                    country: (this.country) ? this.country.country : ""
                }
            )
            .then((response) => {
                this.stateArr = response.data.data;
            });
        },

        // Get All Cities List
        getAllCities() {
            this.city = "";
            this.cityArr = []; 
            this.axios.post(this.$loggedRole+"/getAllCities", {
                    state: (this.state) ? this.state.state : ""
                }
            )
            .then((response) => {
                this.cityArr = response.data.data;
            });
        },

        // Get All Restaurant Companies List
        getAllCompanies() {
            this.companyID = "";
            this.companyArr = [];
            var companyID = 0;
            if(this.$storageData.profile.empTypeID==11) {
                companyID = this.$storageData.profile.companyID;
            }
            this.axios.post(this.$loggedRole+"/getAllCompanies", {
                    companyID:companyID
                }
            )
            .then((response) => {
                this.companyArr = response.data.data;
                if(this.$storageData.profile.empTypeID==11) {
                    this.companyID = (this.companyArr) ? this.companyArr[0] :"";
                }         
            });
        },

        // Get All Reastaurant Brands List
        getRestaurantBrandsByCompanyID(){
            var companyID = this.companyID;
            if(this.$storageData.profile.empTypeID==11) {
                companyID = this.$storageData.profile.companyID;
            }
            this.axios.post(this.$loggedRole+"/getRestaurantBrandsByCompanyID", {
                    companyID : companyID
                }
            )
            .then((response) => {
                this.brandArr = response.data.data;               
            }); 
        },

        getRestaurantBrands(){
            this.axios.post(this.$loggedRole+"/getRestaurantList",{
                    'restID':this.$storageData.profile.restID,
                    'restBranchID':this.$storageData.profile.restBranchID,
                    'empID': this.$storageData.profile.pid,
                    'empTypeID':this.$storageData.profile.empTypeID,
                    companyID : (this.companyID) ? this.companyID.companyID : 0,
                }
            )
            .then((result)=>{
                this.brandArr = result.data.data;            
            });
        },

        getRestaurantCities() {
            var restID = this.brandData.restID;
            if(this.$storageData.profile.empTypeID==11)
            {
                restID = this.$storageData.profile.restID;
            }
            this.axios.post(this.$loggedRole+"/getRestaurantCities", {
                    restID:restID,
                    country: (this.country) ? this.country.country : "",           
                }
            )
            .then((response) => {
                this.cityArr = response.data.data;
                this.city = (this.cityArr) ? this.cityArr[0] : "";
                if(this.$storageData.profile.empTypeID==11){
                    this.getRestaurantBrandsByCompanyID();
                }else{
                    this.readRestaurantBranchData();
                }                                                
            }); 
        },
        
        readRestaurantBranchData(){
            var restBranchID = 0;
            if(this.$storageData.profile.empTypeID == 6) {
                restBranchID = this.$storageData.profile.restBranchID;
            }

            this.axios.post(this.$loggedRole+"/getRestaurantBranchByRestID", {
                    restID :(this.brandData != "") ? this.brandData.restID : this.$storageData.profile.restID,
                    'restBranchID': restBranchID,
                    'empID': this.$storageData.profile.pid,
                    'empTypeID':this.$storageData.profile.empTypeID,
                    city: (this.city) ? this.city.city : "",                        
                    companyID : (this.companyID) ? this.companyID.companyID : 0,                        
                }
            )
            .then((response) => {
                this.branches = response.data.data;
                // this.branchID = (this.branches) ? this.branches[0] :"";
                // this.restBranchID = (this.branchID) ? this.branchID.restBranchID: "";         
            });
        },

        onChangeRestBrand(){
            this.readRestaurantBranchData();
        },

        onchangeBranch(){
            this.restBranchID = (this.branchID) ? this.branchID.restBranchID: "";
        },

        // Get Finance Dashboard Data 
        financeDashboard() {
            this.isTableBusy = true;
            this.axios.post(this.$loggedRole+"/v2/financeDashboard", {
                    country : (this.country) ? this.country.country : "",
                    state : (this.state) ? this.state.state : "",
                    city : (this.city) ? this.city.city : "",
                    companyID : (this.companyID != '') ? this.companyID.companyID : 0,
                    restID : (this.brandData!="") ? this.brandData.restID : this.$storageData.profile.restID  ? this.$storageData.profile.restID:0,
                    restBranchID : (this.branchID!="" && this.branchID!= null) ? this.branchID.restBranchID : this.$storageData.profile.restBranchID ? this.$storageData.profile.restBranchID:0,
                    daterange : this.daterange,
                    paymentType : this.paymentType,
                    orderType : this.orderType,
                    empID: this.$storageData.profile.pid,
                    empTypeID:this.$storageData.profile.empTypeID,
                    viewData : this.tableView.id,
                }
            ) 
            .then((response) => {
                this.isTableBusy = false;
                this.cardData = response.data.overViewData;
                this.tableData = response.data.tableData;
                this.totalSubTotal = response.data.totalSubTotal;
                this.totalSgst = response.data.totalSgst;
                this.totalCgst = response.data.totalCgst;
                this.totalTotal = response.data.totalTotal;
                this.totalSubsidy = response.data.totalSubsidy;
                this.totalCashVoucher = response.data.totalCashVoucher;
                this.totalWallet = response.data.totalWallet;
                this.totalNetPaid = response.data.totalNetPaid;
              this.visibleDataMessage = response.data.visibleDataMessage;
              this.nonInvoicedOrders=response.data.nonInvoicedOrders;
              this.nonInvOrders=response.data.niodrs;
            }).catch((error)=>{
              this.nonInvoicedOrders=0;
              this.nonInvOrders=null;
              this.visibleDataMessage="";
              this.$swal({
                icon: "error",
                title: "Oops...",
                text: error.response.data.message,
              });
            });
        },

        getSelectedViewData(value) {
            if (value.id == 1) {
                this.$delete(this.fields, 0)
                this.fields.unshift({
                    key: "invoiceDate",
                    label: 'Date',
                    sortable: true,
                });
            } else if (value.id == 2) {
                this.$delete(this.fields, 0)
                this.fields.unshift({
                    key: "restaurantName",
                    label: 'Restaurant Name',
                    sortable: true,
                });
            } else if (value.id == 3) {
                this.$delete(this.fields, 0)
                this.fields.unshift({
                    key: "city",
                    label: 'City',
                    sortable: true,
                });
            } else if (value.id == 4) {
                this.$delete(this.fields, 0)
                this.fields.unshift({
                    key: "state",
                    label: 'State',
                    sortable: true,
                });
            }

            this.tableView = value.id;   
            this.isTableBusy = true;     
            this.axios.post(this.$loggedRole+"/v2/financeDashboard", {
                    country : (this.country) ? this.country.country : "",
                    state : (this.state) ? this.state.state : "",
                    city : (this.city) ? this.city.city : "",
                    companyID : (this.companyID != '') ? this.companyID.companyID : "",
                    restID : (this.brandData!="") ? this.brandData.restID : this.$storageData.profile.restID,
                    restBranchID : (this.branchID!="" && this.branchID!= null) ? this.branchID.restBranchID : this.$storageData.profile.restBranchID,
                    daterange : this.daterange,
                    paymentType : this.paymentType,
                    orderType : this.orderType,
                    empID: this.$storageData.profile.pid,
                    empTypeID:this.$storageData.profile.empTypeID,
                    viewData : value.id,
                }
            ) 
            .then((response) => {
                this.isTableBusy = false;
                this.cardData = response.data.overViewData;
                this.tableData = response.data.tableData;
                this.getFields = this.fields;
              this.visibleDataMessage = response.data.visibleDataMessage;
              this.nonInvoicedOrders=response.data.nonInvoicedOrders;
              this.nonInvOrders=response.data.niodrs;
            })
                .catch((error)=>{
              this.nonInvoicedOrders=0;
               this.nonInvOrders=null;
              this.visibleDataMessage="";
              this.$swal({
                icon: "error",
                title: "Oops...",
                text: error.response.data.message,
              });
            });
        },

        applyFilter() {
            if(this.daterange.includes(null)) {
                this.daterange = [];
            }
            this.financeDashboard();      
        },

        clearFilter(){
          this.nonInvoicedOrders=0;
          this.nonInvOrders=null;
          this.visibleDataMessage="";
            this.country ="";
            this.state ="";
            this.city ="";
            this.cityArr = [];
            this.companyID ="";
            this.brandData = "";
            this.brandArr = [];
            this.branchID = "";
            this.branches = [];
            this.restBranchID = ""; 
            this.daterange = [];
            this.paymentType="";
            this.orderType="";
            this.getAllCountry();
            // this.getAllStates();
            // this.getAllCities();
            // this.getAllCompanies();
            // this.getRestaurantBrandsByCompanyID();
            // this.getRestaurantBrands();
            // this.readRestaurantBranchData();
            // this.financeDashboard();

            // let getValueId = [{ name: "Date Wise View", id: 1 }];
            // this.getSelectedViewData(getValueId[0]);
            
            this.tableView = this.tableViewArr.find(tableViewArr => tableViewArr.id === 1);
        },
      generateInvoice(){
if(this.nonInvOrders && this.nonInvoicedOrders > 0){
  this.generatingInvoice=true;
  this.axios.post(this.$loggedRole+"/v2/generate-invoices", {
        orders : this.nonInvOrders,
      }
  )
      .then((response) => {
        this.isTableBusy = false;
        this.generatingInvoice=false;
        this.nonInvoicedOrders=0;
        this.nonInvOrders=null;
        this.$swal({
          icon: "success",
          title: "Generate Invoice",
          text: response.data.message,
        });
      })
      .catch((error)=>{
        this.generatingInvoice=false;
        this.$swal({
          icon: "error",
          title: "Oops...",
          text: error.response.data.message,
        });
      });
}
      }
  },
  middleware: "authentication",
};
</script>

<template>
<Layout>
    <PageHeader :items="items" />
    <!-- Filter -->
    <div class="card" style="margin-top:-30px">
        <div class="card-body">
            <div class="inner mb-2 row" >
                <!-- Country List-->
                <div class="col-md-2">   
                    <label>Country</label>
                    <multiselect v-model="country" :options="countryArr" track-by="country" label="country" @input="getAllStates();" :show-labels="false"></multiselect>
                </div>
                <!-- Country List END -->
                <!-- State List -->
                <div class="col-md-2">   
                    <label>State</label>
                    <multiselect v-model="state" :options="stateArr" track-by="state" label="state" @input="getAllCities();" :show-labels="false"></multiselect>
                </div>
                <!-- State List END -->
                <!-- Cities List -->
                <div class="col-md-2">   
                    <label>City</label>
                    <multiselect v-model="city" :options="cityArr" track-by="city" label="city" @input="getAllCompanies();" :show-labels="false"></multiselect>
                </div>
                <!-- Cities List END --> 
                <!-- Company List -->
                <div class="col-md-2">   
                    <label>Company</label>
                    <multiselect v-model="companyID" :options="companyArr" track-by="companyID" label="companyName" @input="getRestaurantBrandsByCompanyID();" :show-labels="false"></multiselect>
                </div>
                <!-- Compnay List END -->
                <!-- Brand List -->
                <div class="col-md-2">   
                    <label>Restaurant Brand</label>
                    <multiselect v-model="brandData" :options="brandArr" track-by="restID" label="name" @input="readRestaurantBranchData();" :show-labels="false"></multiselect>
                </div>
                <!-- Brand List END -->
                <!-- Branch List -->
                <div class="col-md-2">   
                    <label>Restaurant Branch </label>
                    <multiselect v-model="branchID" :options="branches" track-by="restBranchID" label="branchName" @input="onchangeBranch();" :show-labels="false"></multiselect>
                </div>
                <!-- Branch List END -->
                <!-- Date range -->
                <div class="col-md-2">   
                    <label>Date Range </label>
                    <date-picker v-model="daterange" format="DD MMM Y" value-type="format" range append-to-body lang="en" confirm @input="onchangeBranch();"></date-picker>
                </div>
                <!-- Date range END -->
                <!-- Payment Type -->
                <div class="col-md-2">   
                    <label>Payment Type </label>
                    <multiselect v-model="paymentType" :options="paymentTypeArr" :select-label="'Select'" @input="onchangeBranch();"></multiselect>
                </div>
                <!-- Payment Type END -->
                <!-- Order Type -->
                <div class="col-md-2">   
                    <label>Order Type </label>
                    <multiselect v-model="orderType" :options="orderTypeArr" :select-label="'Select'" @input="onchangeBranch();"></multiselect>
                </div>
                <!-- Order Type END -->
                <!-- Filter Button -->
                <div class="col-md-4">   
                    <button class="btn btn-themeOrange mt-4" v-on:click="applyFilter()"> Apply Filter </button>&nbsp;
                    <button  class="btn btn-themeBrown mt-4" v-on:click="clearFilter()"> Clear Filter </button>
                </div>
                <!-- Filter End -->
            </div>  
        </div>
    </div> 
    <!-- FILTER END -->

    <!-- OVERVIEW START -->
    <div class="col-lg-12">
        <div class="card">
            <div class="card-body">
              <b-alert :show="nonInvoicedOrders > 0"  variant="warning" fade dismissible @dismissed="nonInvoicedOrders=0">
                Invoice for some orders have not been generated yet. To generate invoice for those orders click generate button.
                <button
                    :disabled="generatingInvoice"
                    class="btn btn-themeYellow"
                    @click="generateInvoice"
                >
                  <i class="uil-invoice"></i>Generate Invoice
                </button>
              </b-alert>
              <div
                  v-if="visibleDataMessage != ''"
                  class="text-left text-danger my-2"
              >
                <strong>{{ visibleDataMessage }}</strong>
              </div>
                <b-tabs justified nav-class="nav-tabs-custom" content-class="p-3 text-muted">
                    <!-- Finance Overview -->
                    <b-tab active>
                        <template v-slot:title>
                            <span class="d-inline-block d-sm-none">
                                <i class="fas fa-home"></i>
                            </span>
                            <span class="d-none d-sm-inline-block">Finance Overview</span>
                        </template>                         
                        <!-- Tiles -->
                        <div class="row" style="margin: 10px 0 0;">
                            <!-- Total Sales -->
                            <div class="col-md-6 col-xl-3 overviewCard">
                                <div class="card">
                                    <div class="card-body">
                                        <div>
                                            <h4 class="cardTitle mb-1 mt-1">
                                                {{ $func.formatPrice((cardData.subTotal) ? cardData.subTotal : 0) }}
                                            </h4>
                                            <p class="text-muted mb-0">Total Sale</p>
                                        </div>
                                        <div class="row">
                                            <p class="text-muted mt-3 mb-0 col-7 cardSubtitle1">
                                                <span>
                                                    {{ $func.formatCommaSepNum((cardData.noOfInvoice) ? cardData.noOfInvoice : 0) }}
                                                </span> Total Orders
                                            </p>
                                            <span class="align-center col-5 cardSubtitle2">
                                                <p>
                                                    {{ $func.formatPrice((cardData.subTotal) ? (cardData.subTotal / cardData.noOfInvoice) : 0) }}
                                                </p> Average
                                            </span>
                                        </div>
                                    </div>
                                </div>                            
                            </div>
                            <!-- Total Sales End -->
                            <!-- CGST -->
                            <div class="col-md-6 col-xl-3 overviewCard">
                                <div class="card">
                                    <div class="card-body">
                                        <div>
                                            <h4 class="cardTitle mb-1 mt-1">
                                                {{ $func.formatPrice((cardData.CGST) ? cardData.CGST : 0) }}
                                            </h4>
                                            <p class="text-muted mb-0">CGST</p>
                                        </div>
                                        <div class="row">
                                        <p class="text-muted mt-3 mb-0 col-7 cardSubtitle1">
                                            <span>
                                                {{ $func.formatCommaSepNum((cardData.noOfInvoice) ? cardData.noOfInvoice : 0) }}
                                            </span> No. of Orders
                                        </p>
                                        <span class="align-center col-5 cardSubtitle2">
                                            <p>
                                                {{ $func.formatPrice((cardData.CGST) ? (cardData.CGST / cardData.noOfInvoice) : 0) }}
                                            </p> Average
                                        </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- CGST Ends -->
                            <!-- SGST -->
                            <div class="col-md-6 col-xl-3 overviewCard">
                                <div class="card">
                                    <div class="card-body">
                                        <div>
                                            <h4 class="cardTitle mb-1 mt-1">
                                                {{ $func.formatPrice((cardData.SGST) ? cardData.SGST : 0) }}
                                            </h4>
                                            <p class="text-muted mb-0">SGST</p>
                                        </div>
                                        <div class="row">
                                        <p class="text-muted mt-3 mb-0 col-7 cardSubtitle1">
                                            <span>
                                                {{ $func.formatCommaSepNum((cardData.noOfInvoice) ? cardData.noOfInvoice : 0) }}
                                            </span> No. of Orders
                                        </p>
                                        <span class="align-center col-5 cardSubtitle2">
                                            <p>
                                                {{ $func.formatPrice((cardData.SGST) ? (cardData.SGST / cardData.noOfInvoice) : 0) }}
                                            </p> Average
                                        </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- SGST End -->
                            <!-- Gross Total -->
                            <div class="col-md-6 col-xl-3 overviewCard">
                                <div class="card">
                                    <div class="card-body">
                                        <div>
                                            <h4 class="cardTitle mb-1 mt-1">
                                                {{ $func.formatPrice((cardData.total) ? cardData.total : 0) }}
                                            </h4>
                                            <p class="text-muted mb-0">Gross Sales</p>
                                        </div>
                                        <div class="row">
                                            <p class="text-muted mt-3 mb-0 col-7 cardSubtitle1">
                                                <span>
                                                    {{ $func.formatCommaSepNum((cardData.noOfInvoice) ? cardData.noOfInvoice : 0) }}
                                                </span> No. of Orders
                                            </p>
                                            <span class="align-center col-5 cardSubtitle2">
                                                <p>
                                                    {{ $func.formatPrice((cardData.total) ? (cardData.total / cardData.noOfInvoice) : 0) }}
                                                </p> Average
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- Gross Total End -->          
                        </div>
                        <!-- Tiels End -->
                    </b-tab>
                    <!-- Finance Oveview End -->
                    <!-- Finance List View -->
                    <b-tab>
                        <template v-slot:title>
                            <span class="d-inline-block d-sm-none">
                                <i class="far fa-user"></i>
                            </span>
                            <span class="d-none d-sm-inline-block">Finance List</span>
                        </template>
                        <!-- Datatable -->
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="card">
                                    <div class="card-body">
                                        <div class="row mt-4">
                                            <div class="col-sm-12 col-md-2" style="margin-bottom:5px;" v-if="this.$storageData.login_type == 1">
                                                <div role="group" class="btn-group">
                                                    <button type="button" class="btn btn-themeBrown" @click="downloadSample()">
                                                        Excel
                                                    </button>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-3">
                                                <!-- Table Select -->
                                                <div class="col-md-12">
                                                    <multiselect v-model="tableView" :options="tableViewArr" @select="getSelectedViewData" :select-label="'Select'" track-by="id" label="name"></multiselect>
                                                </div>
                                                <!-- Table Select END -->
                                            </div>
                                            <div class="col-sm-12 col-md-3">
                                                <div id="tickets-table_length" class="dataTables_length">
                                                    <label class="d-inline-flex align-items-center">
                                                        Show &nbsp;&nbsp;
                                                        <b-form-select style="margin-left:5px; margin-right:5px" v-model="perPage" size="sm" :options="pageOptions"></b-form-select>
                                                        &nbsp;&nbsp;&nbsp; entries
                                                    </label>
                                                </div>
                                            </div>
                                            <!-- Search -->
                                            <div class="col-sm-12 col-md-3 row">
                                                <div id="tickets-table_filter" class="dataTables_filter text-md-end">
                                                    <label class="d-inline-flex align-items-center">
                                                        Search:
                                                        <b-form-input v-model="filter" type="search" placeholder="Search..." class="form-control form-control-sm ms-2"></b-form-input>
                                                    </label>
                                                </div>
                                            </div>
                                            <!-- End search -->
                                        </div>
                                        <!-- Table -->
                                        <div class="table-responsive mb-0">
                                            <b-table
                                            striped hover
                                            outlined
                                            bordered
                                            responsive
                                            :items="tableData"
                                            :fields="fields"
                                            :per-page="perPage"
                                            :current-page="currentPage"
                                            :filter="filter"
                                            :filter-included-fields="filterOn"
                                            @filtered="onFiltered"
                                            :busy="isTableBusy"
                                            foot-clone
                                            >
                                                <template #table-busy>
                                                    <div class="text-center text-danger my-2">
                                                    <b-spinner class="align-middle"></b-spinner>
                                                    <strong>Loading...</strong>
                                                    </div>
                                                </template>
                                                <template #cell(invoiceDate)="data">                                              
                                                    {{ data.item.invoiceDate }}
                                                </template>
                                                <template #cell(restaurantName)="data">
                                                    {{ data.item.restaurantName }}
                                                </template>
                                                <template #cell(city)="data">
                                                    {{ data.item.city }}
                                                </template>
                                                <template #cell(state)="data">
                                                    {{ data.item.state }}
                                                </template>
                                                <template #cell(subTotal)="data">                                                    
                                                    <div>{{ $func.formatPrice(data.item.subTotal) }}</div>
                                                </template>
                                                <template #cell(CGST)="data">                                                    
                                                    <div>{{ $func.formatPrice(data.item.CGST) }}</div>
                                                </template>
                                                <template #cell(SGST)="data">                                                    
                                                    <div>{{ $func.formatPrice(data.item.SGST) }}</div>
                                                </template>
                                                <template #cell(total)="data">                                                    
                                                    <div>{{ $func.formatPrice(data.item.total) }}</div>
                                                </template>
                                                <template #cell(subsidy)="data">                                                    
                                                    <div>{{ $func.formatPrice(data.item.subsidy) }}</div>
                                                </template>
                                                <template #cell(cashVoucher)="data">                                                    
                                                    <div>{{ $func.formatPrice(data.item.cashVoucher) }}</div>
                                                </template>
                                                <template #cell(wallet)="data">                                                    
                                                   <div> {{ $func.formatCommaSepNum(data.item.wallet) }}</div>
                                                </template>
                                                <template #cell(netPaid)="data">                                                    
                                                    <div>{{ $func.formatPrice(data.item.netPaid) }}</div>
                                                </template>
                                                <template v-slot:foot(invoiceDate)>
                                                    <span>Total: </span>
                                                </template>
                                                <template v-slot:foot(restaurantName)>
                                                    <span></span>
                                                </template>
                                                <template v-slot:foot(city)>
                                                    <span></span>
                                                </template>
                                                <template v-slot:foot(state)>
                                                    <span></span>
                                                </template>
                                                <template v-slot:foot(subTotal)>          
                                                    <span>{{ $func.formatPrice(totalSubTotal) }}</span>
                                                </template>
                                                <template v-slot:foot(CGST)>
                                                    <span>{{ $func.formatPrice(totalCgst) }}</span>
                                                </template>
                                                <template v-slot:foot(SGST)>
                                                    <span>{{ $func.formatPrice(totalSgst) }}</span>
                                                </template>
                                                <template v-slot:foot(total)>
                                                    <span>{{ $func.formatPrice(totalTotal) }}</span>
                                                </template>
                                                <template v-slot:foot(subsidy)>
                                                    <span>{{ $func.formatPrice(totalSubsidy) }}</span>
                                                </template>
                                                <template v-slot:foot(cashVoucher)>
                                                    <span>{{ $func.formatPrice(totalCashVoucher) }}</span>
                                                </template>
                                                <template v-slot:foot(wallet)>
                                                    <span>{{ $func.formatCommaSepNum(totalWallet) }}</span>
                                                </template>
                                                <template v-slot:foot(netPaid)>
                                                    <span>{{ $func.formatPrice(totalNetPaid) }}</span>
                                                </template>                   
                                            </b-table>
                                        </div>  
                                        <div class="row">
                                            <div class="col">
                                                <div class="dataTables_paginate paging_simple_numbers float-end">
                                                    <ul class="pagination pagination-rounded mb-0">
                                                        <!-- pagination -->
                                                        <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>                                      
                                    </div>
                                </div>
                            </div>
                        </div> 
                        <!-- Datatable End -->
                    </b-tab>
                    <!-- Finance List View End -->                    
                </b-tabs>
            </div>
        </div>
    </div>
    <!-- OVERVIEW END -->
</Layout>
</template>

<style scoped>

.cardBody {
    min-width: 180px!important;
    width:fit-content!important;
}
.cardTitle {
    font-size:18px;
}
.cardSubtitle1 span {
    color:#A4827D;
    font-weight:500;
    font-size:15.5px;
}
.cardSubtitle2 p {
    color:#F3766A;
    font-weight:500;
    font-size:17px;
    margin:0;
}
.overviewCard {
    padding: 0 5px !important;
}
.pagination-rounded .page-link {
    width: 70px;
}

</style>

